@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'),
    url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto Bold'),
    url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'),
    url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

body {
  background-color: #000;
  color: #fff;
  font-family: 'Roboto Medium';
  font-size: 25px;
  text-transform: uppercase;
}

.bg {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.menu {
  list-style-type: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  z-index: 100;
}

.musicMenu {
  text-align: right;
}

.openMenu {
  height: 200px;
  transition: height 0.75s ease-in;
}

.closeMenu {
  height: 0px;
  transition: height 0.75s ease-out;
}

.openMenuButton {
  padding-left: 45%;
  transition: padding-left 0.75s ease-out;
}

.closeMenuButton {
  padding-left: 0%;
  transition: padding-left 0.75s ease-out;
}

.openMusicMenuButton {
  padding-right: 45%;
  transition: padding-right 0.75s ease-out;
  text-align: end;
}

.closeMusicMenuButton {
  padding-right: 0%;
  transition: padding-right 0.75s ease-out;
  text-align: end;
}

.music-menu-container {
  position: fixed;
  flex-direction: column;
  align-items: center;
  top: 10vh;
  left: 0;
  right: 0;
  overflow: hidden;
}

.back-button {
  position: fixed;
  top: 30px;
  right: 30px;
}

.music-list {
  list-style-type: none;
  cursor: pointer;
  text-align: right;
}

.container {
  display: inline-block;
}

.mainMenuContainer {
  position: fixed;
  z-index: 100;
  left: 30px;
  top: 30px;
}

.musicMenuContainer {
  position: fixed;
  z-index: 100;
  right: 30px;
  top: 30px;
}

/* .menuToggle li {
  display: block;
  font-size: 25px;
} */

.container span {
  cursor: pointer;
  display: block;
}

ul {
  padding: 0;
}

a {
  color: rgba(255, 255, 255, 1);
}

a:hover {
  text-decoration: none;
}

a:visited {
  color: #fff;
  text-decoration: none;
}

.pulse {
  cursor: pointer;
}
.pulse:hover {
  animation: pulse 1s infinite;
}

/* .pulse-red {
  animation: pulse-red 1s infinite;
} */
.pulse-red,
.pulse-red::placeholder {
  color: var(--main-text-color);
  animation: pulse-red 2s infinite;
  -webkit-animation: pulse-red 2s infinite;
}

.disabledButton {
  color: rgba(255, 255, 255, 0.7);
  cursor: default !important;
}

.bgTransitionStart {
  animation: bg-transition-start 1s linear;
}

.bgTransitionEnd {
  animation: bg-transition-end 1s linear;
}

.rights {
  writing-mode: vertical-lr;
  position: fixed;
  bottom: 5vh;
  right: 5px;
  cursor: default !important;
  user-select: none;
  font-size: 10px;
}

@keyframes bg-transition-start {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1000);
  }
}

@keyframes bg-transition-end {
  0% {
    transform: scale(1000);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    color: rgba(255, 255, 255, 1);
  }
  50% {
    color: rgba(255, 255, 255, 0.5);
  }
  100% {
    color: rgba(255, 255, 255, 1);
  }
}

@keyframes pulse-red {
  0% {
    --main-text-color: rgba(255, 255, 255, 1);
  }
  50% {
    --main-text-color: rgba(255, 0, 0, 1);
  }
  100% {
    --main-text-color: rgba(255, 255, 255, 1);
  }
}
