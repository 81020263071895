* {
  font-size: 25px;
}

.footer {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.footer span {
  cursor: pointer;
}

.contactForm {
  overflow: hidden;
}

.newsletterForm {
  overflow: hidden;
}

.openContactForm {
  height: 60vh;
  transition: height 1s ease-out;
}

.closeContactForm {
  height: 0px;
  transition: height 1s ease-out;
}

.openNewsletterForm {
  height: 60vh;
  transition: height 1s ease-out;
}

.closeNewsletterForm {
  height: 0px;
  transition: height 1s ease-out;
}

.sendButton {
  color: #fff;
  display: initial !important;
}

textarea {
  overflow: hidden;
  appearance: none;
  outline: none;
  background-color: transparent;
  -webkit-appearance: none;
  font-size: 16px;
  border: 0;
  color: #fff;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
  resize: none;
}

textarea::placeholder {
  color: #fff;
  font-size: 16px;
}

input {
  overflow: visible;
  appearance: none;
  outline: none;
  background-color: transparent;
  -webkit-appearance: none;
  font-size: 16px;
  border: 0;
  color: #fff;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
}

input::placeholder {
  color: #fff;
  font-size: 16px;
}

.grecaptcha-badge {
  display: none !important;
}

@media (max-width: 576px) {
  * {
    font-size: 15px;
  }
  textarea {
    font-size: 25px;
  }
  h1 {
    font-size: 25px;
  }
  input {
    font-size: 15px;
  }
  textarea {
    font-size: 15px;
  }
  .music-menu-container {
    height: 25vh;
  }
  .openContactForm {
    height: 60vh;
  }
  .rights {
    font-size: 6px;
  }
}
